exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-car-insurance-rules-js": () => import("./../../../src/pages/car-insurance-rules.js" /* webpackChunkName: "component---src-pages-car-insurance-rules-js" */),
  "component---src-pages-dlcn-ocb-js": () => import("./../../../src/pages/dlcn-ocb.js" /* webpackChunkName: "component---src-pages-dlcn-ocb-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-rules-js": () => import("./../../../src/pages/insurance-rules.js" /* webpackChunkName: "component---src-pages-insurance-rules-js" */),
  "component---src-pages-motorcycle-insurance-rules-js": () => import("./../../../src/pages/motorcycle-insurance-rules.js" /* webpackChunkName: "component---src-pages-motorcycle-insurance-rules-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-terms-of-service-agri-js": () => import("./../../../src/pages/terms-of-service-agri.js" /* webpackChunkName: "component---src-pages-terms-of-service-agri-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-terms-of-service-ocb-js": () => import("./../../../src/pages/terms-of-service-ocb.js" /* webpackChunkName: "component---src-pages-terms-of-service-ocb-js" */),
  "component---src-pages-terms-of-service-other-js": () => import("./../../../src/pages/terms-of-service-other.js" /* webpackChunkName: "component---src-pages-terms-of-service-other-js" */),
  "component---src-pages-terms-of-service-vcb-js": () => import("./../../../src/pages/terms-of-service-vcb.js" /* webpackChunkName: "component---src-pages-terms-of-service-vcb-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

